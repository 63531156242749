@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap);
.group-input {
  background-color: white;
  border-radius: 5px;
  width: 100%;
}
.group-input.error {
  margin-bottom: 0;
}
.group-input.error .MuiInputBase-formControl {
  border: 1px solid #f44336;
}
.group-input .MuiInputBase-formControl {
  background-color: white;
  border-radius: 5px;
}
.group-input .MuiFilledInput-input {
  border-radius: 5px;
}
.group-input .MuiFilledInput-underline:before {
  border-bottom: 0px;
}

.error-text {
  color: #f44336;
  font-size: 14px;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.pms-button {
  text-align: center;
  margin: 0 auto;
}
.pms-button.loading {
  position: relative;
}
.pms-button.loading button {
  background-color: lightgray !important;
}
.pms-button.loading .loading-indicator {
  color: #E28020;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.login-container {
  height: calc(100vh - 64px);
}

.login-card {
  max-width: 450px;
  width: 100%;
  max-height: 550px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.login-card .login-form {
  background-color: #E28020;
}
.login-card .login-form h1 {
  color: white;
  font-weight: bold;
}
.login-card .form-action button {
  text-align: center;
  margin: 0 auto;
  background-color: #E28020;
  color: white;
  min-width: 100px;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.dashboard.container {
  height: calc(100vh - 64px);
  position: relative;
}
.dashboard.container .wrapper {
  padding: 24px;
}
.dashboard.container .line-chart-bg,
.dashboard.container .bar-chart-bg,
.dashboard.container .pie-chart {
  background-color: #404040;
  height: 100%;
}
.dashboard.container .card-header {
  background-color: #3e5e84;
  color: white;
  padding: 8px;
  padding-left: 16px;
  border-bottom: 1px solid #bfbfbf;
}
.dashboard.container .leaflet-container {
  max-height: 350px;
}
.dashboard.container .pie-chart {
  height: 350px;
}
.dashboard .damage-distribution-container {
  background-color: #404040;
}
.dashboard .damage-distribution-container .MuiCardContent-root {
  padding: 0 !important;
}
.dashboard .MuiCard-root {
  text-align: center;
}
.dashboard .MuiCard-root .MuiCircularProgress-colorPrimary {
  color: #E28020;
}
.dashboard .route-selection-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.dashboard .route-selection-dropdown .MuiAutocomplete-root {
  padding-left: 10px;
}
.dashboard .route-selection-dropdown .MuiAutocomplete-root .MuiInput-underline:before {
  content: none;
}
.dashboard .route-selection-dropdown .MuiInputLabel-root {
  color: white;
}
.dashboard .route-selection-dropdown .MuiInputLabel-formControl {
  -webkit-transform: translate(0, 8px) scale(1);
          transform: translate(0, 8px) scale(1);
  font-size: 14px;
}
.dashboard .route-selection-dropdown .MuiAutocomplete-clearIndicator,
.dashboard .route-selection-dropdown .MuiAutocomplete-popupIndicator,
.dashboard .route-selection-dropdown .MuiAutocomplete-input {
  color: white;
}
.dashboard .route-selection-dropdown label + .MuiInput-formControl {
  margin-top: 0;
}

.MuiAutocomplete-popper .MuiAutocomplete-option {
  font-size: 12px;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.upload-btn {
  background-color: #E28020 !important;
  color: white !important;
}
.upload-btn .MuiSvgIcon-root {
  margin-right: 10px;
}
.road-information-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 636px);
}
.road-information-container .MuiTableCell-root {
  padding: 0 5px !important;
  font-size: 12px !important;
  height: 54px;
}
.road-information-container .MuiTableCell-root .MuiIconButton-root {
  padding: 10px;
}
.road-information-container .MuiTableCell-root .MuiTablePagination-root:last-child {
  height: 100%;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.loading-container {
  position: absolute;
  top: 0px;
  left: 56px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10000;
}
.loading-container .lds-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.leaflet-container {
  width: 100%;
}
.leaflet-container .point-surveyed-type.checked {
  opacity: 0.5;
}
.leaflet-container .point-surveyed-type.checked.connection {
  opacity: 0.25;
}

.leaflet-popup-content {
  margin: 0 !important;
  border-radius: 0;
}

.leaflet-routing-container {
  display: none;
}

.map-form-section {
  border-right: 1px solid #E28020;
  position: relative;
}

.toggle-show-hide-button {
  position: absolute !important;
  right: -10px;
  top: 50%;
  z-index: 1000;
  background-color: #e28020 !important;
  border-radius: 0 !important;
  color: white !important;
  padding: 0 !important;
  height: 60px;
  width: 10px;
  -webkit-transform: translate(0, -60px);
          transform: translate(0, -60px);
}
.toggle-show-hide-button.hide {
  left: 57px;
  -webkit-transform: translate(0, -30px);
          transform: translate(0, -30px);
}

.upload-container {
  width: 400px;
  max-width: 100%;
}
.upload-container .upload-header {
  background-color: #E28020;
  color: white;
}
.upload-container .upload-form {
  text-align: center;
}
.upload-container .upload-form .files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 20px 0px 40px 10%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.upload-container .upload-form .files input:focus {
  outline: 2px dashed #E28020;
  outline-offset: -10px;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}
.upload-container .upload-form .files {
  position: relative;
}
.upload-container .upload-form .color input {
  background-color: #f1f1f1;
}
.upload-container .upload-form .files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 30px;
  content: " or drag it here ";
  display: block;
  margin: 0 auto;
  color: #E28020;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.upload-container .form-action button {
  text-align: center;
  margin: 0 auto;
  background-color: #E28020;
  color: white;
  min-width: 100px;
}

.detail-content-container {
  background-color: #E28020;
}
.detail-content-container .MuiTabs-indicator {
  background-color: white;
}

.deterioration-rank-card {
  width: 100%;
  max-width: 200px;
  border: 1px solid #E28020;
  padding: 0 8px 8px 8px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 8px;
}
.deterioration-rank-card > p {
  text-align: center;
}
.deterioration-rank-card .distress-label {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 30px;
  padding: 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.deterioration-rank-card .distress-label.hover:hover {
  border: 1px solid #E28020;
  cursor: pointer;
}
.deterioration-rank-card .distress-label.selected {
  padding: 0 5px;
  border: 1px solid #E28020;
  cursor: pointer;
}
.deterioration-rank-card .distress-label .distress-dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
}
.deterioration-rank-card .distress-label h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popup-road-detail .leaflet-popup-content-wrapper {
  border-radius: 0px;
}
.popup-road-detail .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 450px !important;
}
.popup-road-detail .MuiButtonBase-root {
  width: 50%;
}
.popup-road-detail.popup-content-point .leaflet-popup-content-wrapper .leaflet-popup-content {
  width: 600px !important;
}

path.leaflet-interactive:hover {
  stroke-width: 10px;
  cursor: default;
}
path.leaflet-interactive.point-surveyed-type {
  fill-opacity: 1;
}

.attribute-list .MuiTableCell-root {
  padding: 0 5px !important;
  font-size: 12px !important;
  height: 32px;
}
.attribute-list .MuiTableCell-root .MuiIconButton-root {
  padding: 4px;
}
.attribute-list .MuiTablePagination-root:last-child {
  height: 100%;
}
.attribute-list .MuiTablePagination-toolbar {
  min-height: 36px;
}

.map-panel-type {
  background-color: white !important;
}
.map-panel-type .type-selected {
  background-color: #E28020;
  color: white;
}

.btn-download-shape-file {
  min-width: 40px !important;
  background-color: white !important;
}
.btn-download-shape-file:hover path {
  color: #E28020;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.gis-map-detail {
  padding: 10px;
  overflow: auto;
  height: calc(100vh - 64px);
}
.gis-map-detail .top-actions {
  margin-top: 10px;
  text-align: center;
}
.gis-map-detail .top-actions button {
  padding: 2px 10px;
  margin-bottom: 10px;
}
.gis-map-detail .filter-card {
  padding: 10px;
  background-color: #E28020;
  border-radius: 5px;
}
.gis-map-detail .filter-card select {
  padding: 10px !important;
}
.gis-map-detail .filter-card .MuiFormControl-root {
  border-radius: 5px;
  background-color: white;
  width: 100%;
  margin-top: 0;
}
.gis-map-detail .filter-card .MuiFormControl-root > label {
  -webkit-transform: translate(14px, 12px) scale(1);
          transform: translate(14px, 12px) scale(1);
}
.gis-map-detail .filter-card .filter-btn {
  background-color: white;
  color: #E28020;
}
.gis-map-detail .filter-card .MuiOutlinedInput-input {
  padding: 12px 14px;
}
.gis-map-detail .filter-card .clear-dates {
  text-align: center;
}
.gis-map-detail .filter-card .clear-dates span {
  color: white;
}
.gis-map-detail .filter-card .delete-btn {
  color: #dc3545;
  height: 80%;
  min-width: 100%;
}
.gis-map-detail .filter-card .delete-btn .MuiButton-startIcon {
  margin: 0;
}
.gis-map-detail .upload-container {
  margin: 0 auto;
  margin-top: 20px;
  width: 400px;
  max-width: 100%;
}
.gis-map-detail .upload-container .file-input-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  box-shadow: none;
  border: 1px solid lightgray;
  margin: 10px 0;
}
.gis-map-detail .upload-container .file-input-container .input-label {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px 0 0 4px;
  width: 65px;
}
.gis-map-detail .upload-container .file-input-container .input-label:hover {
  background-color: #E28020;
}
.gis-map-detail .upload-container .file-input-container .input {
  flex: 1 1;
  padding-left: 10px;
}
.gis-map-detail .upload-container .file-input-container .input-file {
  display: none;
}
.gis-map-detail .upload-container .file-input-container .icon-button {
  padding: 5px;
}
.gis-map-detail .upload-container .file-input-container .icon-button:hover {
  color: #E28020;
}
.gis-map-detail .upload-container .list-files {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow: auto;
}
.gis-map-detail .upload-container .list-files .file-name {
  padding-left: 2px;
  border: 1px solid #E28020;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.gis-map-detail .upload-container .list-files .file-name .delete-button {
  color: lightcoral;
  padding: 2px;
}

.MuiBox-root {
  padding: 0 !important;
}

.list-rate table {
  margin-bottom: 5px;
}
.list-rate .MuiTableCell-sizeSmall {
  padding: 1px;
  border: 1px solid;
  font-size: 8.5px;
  min-width: 85px;
  height: 25px;
}
.list-rate .MuiTableCell-sizeSmall:last-child {
  padding-right: 0 !important;
}

.summary-card {
  flex-grow: 1;
}
.summary-card .tabs-container {
  background-color: lightgray;
}
.summary-card .MuiTabs-indicator,
.summary-card .Mui-selected {
  background-color: #E28020;
}
.summary-card .tab-content {
  height: 390px;
  text-align: center;
}
.summary-card .tab-content.point-type {
  height: 100%;
}
.summary-card .tab-content.point-type .route-selection-dropdown {
  display: flex;
  justify-content: center;
}
.summary-card .tab-content__carousel {
  padding-top: 15px;
}
.summary-card .tab-content__carousel .carousel .slide {
  background-color: white;
  padding: 0 24px;
}
.summary-card .tab-content__carousel .carousel.carousel-slider .control-arrow {
  background-color: white;
}
.summary-card .tab-content__carousel .carousel .control-next.control-arrow:before {
  border-left: 12px solid gray;
}
.summary-card .tab-content__carousel .carousel .control-prev.control-arrow:before {
  border-right: 12px solid gray;
}
.summary-card .tab-content__carousel .carousel.carousel-slider .control-arrow:hover {
  background: transparent;
}
.summary-card .tab-content__carousel .carousel .control-arrow:before,
.summary-card .tab-content__carousel .carousel.carousel-slider .control-arrow:before {
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
}
.summary-card .tab-content__title {
  text-align: center;
  font-size: 16px;
}
.summary-card .tab-content__title .MuiInput-underline:before {
  content: none;
}
.summary-card .tab-content__data {
  padding: 0 5px;
}
.summary-card .tab-content__data--name {
  text-decoration: underline;
}
.summary-card .tab-content .MuiToolbar-root {
  display: none;
}
.summary-card .tab-content .MuiCircularProgress-colorPrimary {
  margin-top: 10px;
}
.summary-card .tab-chart {
  height: 390px;
}
.summary-card .tab-chart__pie {
  height: calc(330px);
  width: calc(330px);
  margin: 0 auto;
}
.summary-card .tab-chart .route-selection-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
}
.summary-card .tab-chart .route-selection-dropdown .MuiAutocomplete-root {
  padding-left: 10px;
}
.summary-card .tab-chart .route-selection-dropdown .MuiAutocomplete-root .MuiInput-underline:before {
  content: none;
}
.summary-card .tab-chart .route-selection-dropdown .MuiFormLabel-root.Mui-disabled {
  color: black;
}
.summary-card .tab-chart .route-selection-dropdown .MuiInputLabel-formControl {
  -webkit-transform: translate(0, 8px) scale(1);
          transform: translate(0, 8px) scale(1);
  font-size: 14px;
}
.summary-card .tab-chart .route-selection-dropdown label + .MuiInput-formControl {
  margin-top: 0;
  flex-wrap: nowrap !important;
}
.summary-card .tab-chart .route-selection-dropdown .MuiAutocomplete-inputRoot {
  flex-wrap: nowrap !important;
}
.summary-card .tab-chart .leaflet-map-pane canvas {
  margin: 0 auto;
}

.date-has-data .MuiIconButton-label p {
  color: #E28020;
  font-weight: bold;
}
.date-has-data.selected-date .MuiIconButton-label p {
  color: white;
  font-weight: bold;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  margin: 8px;
  border: 5px solid #ffa142;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ffa142 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.road-surveyed-container {
  padding: 10px;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: column;
}
.road-surveyed-container .MuiTableCell-root {
  padding: 0 5px !important;
  font-size: 12px !important;
  height: 43px;
}
.road-surveyed-container .MuiTableCell-root .MuiIconButton-root {
  padding: 10px;
}
.road-surveyed-container .MuiTablePagination-root:last-child {
  height: 100%;
}

.upload-surveyed-container {
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
}
.upload-surveyed-container .file-input-container {
  display: flex;
  align-items: stretch;
  width: 100%;
  box-shadow: none;
  border: 1px solid lightgray;
  margin: 10px 0;
}
.upload-surveyed-container .file-input-container .input-label {
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px 0 0 4px;
  width: 65px;
}
.upload-surveyed-container .file-input-container .input-label:hover {
  background-color: #E28020;
}
.upload-surveyed-container .file-input-container .input {
  flex: 1 1;
  padding-left: 10px;
}
.upload-surveyed-container .file-input-container .input-file {
  display: none;
}
.upload-surveyed-container .file-input-container .icon-button {
  padding: 5px;
}
.upload-surveyed-container .file-input-container .icon-button:hover {
  color: #E28020;
}
.upload-surveyed-container .list-files {
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  max-height: 150px;
  overflow: auto;
}
.upload-surveyed-container .list-files .file-name {
  padding-left: 2px;
  border: 1px solid #E28020;
  border-radius: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.upload-surveyed-container .list-files .file-name .delete-button {
  color: lightcoral;
  padding: 2px;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.user-form {
  padding: 0px 40px;
  margin: 0 auto;
  text-align: center;
}
.user-form h1 {
  color: #E28020;
}
.user-form form {
  text-align: left;
}
.user-form .field {
  margin: 8px;
  width: 100%;
}
.user-form .section {
  margin: 8px;
}
.user-form .actions {
  text-align: center;
}
.user-form .actions > * {
  margin: 8px;
  max-width: 100px;
  width: 100%;
}
.user-form .MuiGrid-container {
  padding: 46px 0;
}
.user-form .MuiGrid-item {
  padding: 0 40px !important;
}
.user-form .MuiGrid-item.right {
  border-left: 1px solid lightgray;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.surface-form {
  padding: 0px 40px;
  margin: 0 auto;
  text-align: center;
}
.surface-form h1 {
  color: #E28020;
}
.surface-form form {
  text-align: left;
}
.surface-form .field {
  margin: 8px;
  width: 100%;
}
.surface-form .section {
  margin: 8px;
}
.surface-form .actions {
  text-align: center;
}
.surface-form .actions > * {
  margin: 8px;
  max-width: 100px;
  width: 100%;
}
.surface-form .MuiGrid-container {
  padding: 46px 0;
}
.surface-form .MuiGrid-item {
  padding: 0 40px !important;
}
.surface-form .MuiGrid-item.right {
  border-left: 1px solid lightgray;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}

.setting-form {
  padding: 0px 40px;
  margin: 0 auto;
  text-align: center;
}
.setting-form .heading {
  font-weight: bold;
}
.setting-form h1 {
  color: #E28020;
}
.setting-form form {
  text-align: left;
}
.setting-form .field {
  margin: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
}
.setting-form .field .MuiOutlinedInput-root {
  width: 100%;
  margin-right: 8px;
}
.setting-form .section {
  margin: 8px;
}
.setting-form .actions {
  text-align: center;
}
.setting-form .actions > * {
  margin: 8px;
  max-width: 100px;
  width: 100%;
}
.setting-form .MuiGrid-container {
  padding: 16px 0;
}
.setting-form .MuiGrid-item {
  padding: 0 40px !important;
}
.setting-form .MuiGrid-item.right {
  border-left: 1px solid lightgray;
}
* {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  background-color: unset !important;
}
body .authenticated {
  padding-left: 57px;
}

.full-screenable-node {
  background-color: white !important;
}

.top-nav {
  background-color: #E28020;
  min-height: 64px;
  display: flex;
  justify-content: center;
}
.top-nav h1 {
  color: white;
  margin: 0;
  text-align: center;
  line-height: 64px;
  width: 100%;
  margin-right: 50px;
}
.top-nav .logo {
  width: 64px;
  height: 64px;
  margin-left: 20px;
}
.top-nav .MuiAppBar-colorPrimary {
  background-color: #E28020;
}
.top-nav .MuiListItemIcon-root {
  color: #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft {
  border-right: 1px solid #E28020;
}
.top-nav .MuiDrawer-paperAnchorLeft .MuiList-padding {
  padding-top: 0;
}

.container-fluid {
  padding: 0 40px;
}

.upload-container .upload-header {
  padding: 5px 10px;
}
.upload-container .MuiCardHeader-avatar {
  line-height: 10px;
}

.main-btn {
  background-color: #E28020 !important;
  color: white !important;
}

.pms-processing .process-indicator {
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
.pms-processing .process-indicator label {
  font-size: 25px;
  color: #E28020;
}
.pms-processing .MuiPaper-root {
  max-width: 400px;
  width: 100%;
}
.pms-processing .MuiDialogTitle-root {
  text-align: center;
}
.pms-processing .MuiLinearProgress-root {
  height: 20px;
  background-color: rgba(32, 148, 243, 0.3);
}
.pms-processing .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #E28020;
}
.pms-processing .MuiDialogActions-root {
  justify-content: center;
}
.pms-processing .MuiDialogActions-root button {
  background-color: #E28020;
  color: white;
}

.MuiPaper-root {
  overflow: auto;
}

.MuiCircularProgress-colorPrimary {
  color: #E28020 !important;
}

.menu-selected {
  background-color: #E28020 !important;
}
.menu-selected .MuiSvgIcon-root {
  color: white !important;
}
.menu-selected .MuiTypography-root {
  color: white !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #E28020 !important;
}

.MuiPickersDay-daySelected {
  background-color: #E28020 !important;
}

.swal2-container {
  z-index: 10000 !important;
}

.ReactModal__Overlay {
  z-index: 10000 !important;
}

.Mui-checked path {
  fill: #E28020;
}

.permission-required-content {
  text-align: center;
  padding-top: 50px;
}
.permission-required-content h1 {
  color: #dc3545;
}
